import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import PBLogo from '../../images/assets/Punkbrandz-Logo.svg';
import Stefan from '../../images/team/Stefan.png';
import Matt from '../../images/team/Matt.png';
import Jairon from '../../images/team/Jairon.png';
import Brandon from '../../images/team/Brandon.png';
import ContactUs from '../HomePage/ContactUsSection';

const AboutUs = () => {

    return (
        <div className='main'>
        <Container>
            <Row className='pt-5 pb-4'>
                <Col className='text-left col-md-6'>
                    <h1 className='text-secondary font-secondary m-0 p-0' style={{ fontSize: "12rem" }}>WE ARE</h1>
                    <Image className='w-100 mw-100 mb-0 me-0 ms-0 p-0' style={{ marginTop: "-3rem" }} src={PBLogo} alt="Punkbrands logo" />
                </Col>
            </Row>
            <Row className='pb-4 pt-4'>
                <Col>
                    <h3 className='text-secondary font-primary'>At <span className='text-primary'>PunkBrandz</span>, we're not just another branding agency, we're a rebellion against the ordinary. 
                        We believe in pushing the boundaries of brand design, creating the most unique and distinctive identities that stand out from the rest.  We're the provocateurs of branding, turning your brand into a UNIQUE masterpiece that defies convention and demands attention!</h3>
                </Col>
            </Row>
            <Row className='pb-3 pt-3'>
                <Col>
                    <h1 className='text-secondary font-primary'>JOIN THE REVOLUTION AND DONT <span className='text-primary' style={{ textDecoration: "line-through" }}>CONFORM!</span></h1>
                </Col>
            </Row>
            <Row className='pb-0 pt-4'>
                <Col>
                    <h1 className='text-primary font-primary'>THE PUNKS</h1>
                </Col>
            </Row>
            <Row className='pb-0 pt-0'>
                <Col className='col-md-6 ps-5 pe-5 mt-5 pb-1'>
                    <div className='text-center mw-100'>
                        <Image className='w-50' src={Stefan} alt="CEO and Creative Director Stefan Franeta" />
                        <h3 className='text-secondary font-primary mb-0'>STEFAN FRANETA</h3>
                        <h4 className='text-primary font-primary mb-0' style={{ marginTop: "-.5rem" }}>CEO/CREATIVE DIRECTOR</h4>
                    </div> 
                    <p className='text-secondary body-font'>A seasoned graphic artist and senior art director with over 14 years of experience in the industry. I've had the privilege of working with renowned companies such as Disney, Fox, Sony, Yokohama, Mercury Insurance, PUBG, and the Ghostbusters franchise. With my expertise in design and branding, I have collaborated with major corporations like Sony, Disney, Marvel, WWE, Mercury Insurance, and Yokohama Tires. Throughout my career, I have developed a passion for creating captivating visuals.</p>
                </Col>
                <Col className='col-md-6 pe-5 ps-5 pb-0 pt-3'>
                    <div className='text-center mw-100'>
                        <Image className='w-50' src={Jairon} alt="Lead Developer Jairon Popal" />
                        <h3 className='text-secondary font-primary'>JAIRON POPAL</h3>
                        <h4 className='text-primary font-primary' style={{ marginTop: "-.7rem" }}>LEAD DEVELOPER</h4>
                    </div>
                    <p className='text-secondary body-font'>Jairon has 12 years of Software Development experience. At Kaiser Permanente, she worked on two of their internal web applications. Previously, at Doctorsoft, she worked on an EMR (Electronic Medical Record) web and mobile app specialized for Ophthalmologists. Jairon has a diverse technical background. She received her Bachelor's Degree in Electrical Engineering, with a focus on Communication Systems. Interested in growing her skill sets, she's taken graduate-level distance learning courses in Computer Networks, High Performance Computer Architecture, and Artificial Intelligence. In her personal time, she uses her academic background to pursue projects that involve embedded software. She has experience using Java, MySQL, SQL Server, HTML, CCS, Git, C/C++, Python, and experience with Linux architecture and sysadmin.</p>
                </Col>
            </Row>
            <Row className='pb-0 pt-0 mb-5'>
                <Col className='col-md-6 ps-5 pe-5 pt-3 pb-0'>
                    <div className='text-center mw-100'>
                        <Image className='w-50' src={Brandon} alt="Lead 3D Artist" />
                        <h4 className='text-secondary font-primary'>BRANDON LERRY</h4>
                        <h5 className='text-primary font-primary' style={{ marginTop: "-.7rem" }}>LEAD 3D Artist</h5>
                    </div>
                    <p className='text-secondary body-font'>Brandon, in just one year of posting his work online (2022-2023), Brandon has amassed 48+ million views on Tik Tok and Instagram, reaching virality on numerous occasions. His background in filmmaking allows him to blend cinematic style with animation, creating visually compelling, photorealistic content that connects with a wide audience.
                    Over the last year, Brandon has had the pleasure of working on campaigns with amazing clients such as Shaq, BMW, Blink-182, and MVMT.</p>
                </Col>
            </Row>
        </Container>
        <div className='bg-secondary w-100 m-0 p-0'>
            <Container>
            <Row className='pt-5 pb-0 bg-secondary'>
                <Col>
                    <h1 className='text-dark font-primary' style={{ fontSize: "3rem" }}>PUNK MANIFESTO</h1>
                </Col>
            </Row>
            <Row className='pt-5 pb-5 bg-secondary'>
                <Row className='mw-100 m-0 p-0'>
                    <Col className='col-md-6 col-12'>
                        <h1 className='text-primary font-primary'>UNLEASHING ORIGINALITY</h1>
                        <p className='text-dark body-font pe-5 me-3' style={{ fontSize: "1.4rem"}}>At Punkbrandz, we're fueled by the electric charge of originality. We seek out clients who dare to challenge the status quo, those eager to break free from the mundane and explore the uncharted territories of brand expression.</p>
                    </Col>
                    <Col className='col-md-6'>
                        <h1 className='text-primary font-primary'>FEARLESS CREATIVITY</h1>
                        <p className='text-dark body-font pe-5 me-3' style={{ fontSize: "1.4rem" }}>In our world, there's no room for mediocrity. We thrive on fearless creativity, pushing boundaries and embracing the unexpected. It's this relentless pursuit of the extraordinary that propels our clients' brands to stand out amidst the noise.</p>
                    </Col>
                </Row>
            </Row>
            <Row className='pt-5 bg-secondary' style={{ paddingBottom: "10rem" }}>
                <Row className='mw-100 m-0 p-0'>
                    <Col className='col-md-6 col-12'>
                        <h1 className='text-primary font-primary'>CRAFTING AUTHENTICITY</h1>
                        <p className='text-dark body-font pe-5 me-3' style={{ fontSize: "1.4rem"}}>Authenticity is our north star. We craft brands that resonate on a deeper level, weaving narratives that are genuine and true to the essence of our clients' visions. Our commitment to authenticity ensures that every interaction with the brand feels like a genuine connection.</p>
                    </Col>
                    <Col className='col-md-6'>
                        <h1 className='text-primary font-primary'>ELEVATING EXPERIENCES</h1>
                        <p className='text-dark body-font pe-5 me-3' style={{ fontSize: "1.4rem" }}>We're not just in the business of creating brands we're in the business of crafting unforgettable experiences. We believe in the power of design to evoke emotion, spark curiosity, and leave a lasting impression. With every project, we aim to elevate experiences, transforming ordinary moments into extraordinary memories.</p>
                    </Col>
                </Row>
            </Row>
            </Container>
            </div>
            <ContactUs />
        </div>
    )
};

export default AboutUs;