import React from 'react';
import HomePage from './components/HomePage/HomePage';
import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Services';
import Work from './components/Work/Work';

function App() {
  const location = useLocation();

  return (
    <div className="main">
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/work' element={<Work />} />
      </Routes>
      {location.pathname !== '/services' && <Footer />}
    </div>
  );
}

export default App;
