import React, { useRef, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ContactUs from '../HomePage/ContactUsSection';
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax';
import Background from '../../images/assets/PB_Web_BG.jpg';
import Footer from '../Footer/Footer';
import CerealBox from '../../images/parallax/PB_Box.png';
import Cereal from '../../images/parallax/PB_Cereal.png';
import CerealOF from '../../images/parallax/PB_Cereal_OOF.png';
import Spoon from '../../images/parallax/PB_Spoon.png';
import Bowl from '../../images/parallax/PB_Bowl_Milk.png';
import { ScrollRotate } from 'react-scroll-rotate';


const Services = () => {
    const parallax = useRef<IParallax>(null!);
    // const imageRef = useRef<HTMLImageElement>(null!);
    const [isMobile, setIsMobile] = useState<Boolean>(false);

    // useEffect(() => {
    //     window.addEventListener('scroll', event => {
    //       requestAnimationFrame(() => {
    //         const rotation = window.scrollY / 10 % Math.PI;
    //         imageRef.current.style.transform = `rotate(${rotation}deg)`;
    //       });
    //     });
    //   }, []);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Assuming devices with width <= 768px are mobile
        };
    
        handleResize(); // Set the initial value
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className='main'>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', top: '0', left: '0' }}>
                <Parallax ref={parallax} pages={4}>
                    <ParallaxLayer 
                        offset={0}
                        speed={0}
                        factor={4}
                        style={{
                            backgroundImage: `url('${Background}')`,
                            backgroundSize: 'cover'
                        }}
                    />

                    <ParallaxLayer offset={0.1} speed={0}>
                        <h1 className='font-secondary text-primary ps-5 pe-5 pt-3 pb-0 mb-0' style={{ fontSize: "10rem" }} >SERVICES</h1>
                    </ParallaxLayer>
                    
                    <ParallaxLayer offset={0.98} speed={0.45}>
                        <Image src={CerealOF} style={{ width: "4%", marginLeft: "71%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.29} speed={0}>
                        <Image src={CerealBox} style={{ width: "75%", marginLeft: "12.5%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.25} speed={0.25}>
                        <Image src={Cereal} style={{ width: "5%", marginLeft: "47.5%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.3} speed={0.3}>
                        <Image src={Cereal} style={{ width: "3%", marginLeft: "60%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.4} speed={-0.3}>
                        <Image src={Cereal} style={{ width: "3%", marginLeft: "37%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.43} speed={-0.4}>
                        <Image src={Cereal} style={{ width: "4%", marginLeft: "78%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.5} speed={0.35}>
                        <Image src={Cereal} style={{ width: "5%", marginLeft: "70%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.55} speed={-0.25}>
                        <Image src={Cereal} style={{ width: "7%", marginLeft: "10%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.8} speed={-0.3}>
                        <Image src={CerealOF} style={{ width: "3%", marginLeft: "10%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.8} speed={0.45}>
                        <Image src={Cereal} style={{ width: "4%", marginLeft: "75%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={0.99} speed={0.3}>
                        <Image src={CerealOF} style={{ width: "5%", marginLeft: "15%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.05} speed={-0.35}>
                        <Image src={CerealOF} style={{ width: "5%", marginLeft: "20%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1} speed={0.3}>
                        <Image src={CerealOF} style={{ width: "3%", marginLeft: "75%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.4} speed={0.5}>
                        <Image src={Spoon} style={{ width: "30%", marginLeft: "10%", transform: "scale(-1, 1)" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.6} speed={-0.4}>
                        <Image src={Cereal} style={{ width: "7%", marginLeft: "67%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.7} speed={-0.3}>
                        <Image src={Cereal} style={{ width: "7%", marginLeft: "12%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.85} speed={0.3}>
                        <Image src={Cereal} style={{ width: "4%", marginLeft: "35%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.9} speed={0.45}>
                        <Image src={Cereal} style={{ width: "6%", marginLeft: "55%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.95} speed={-0.25}>
                        <Image src={Cereal} style={{ width: "6%", marginLeft: "75%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2} speed={0.35}>
                        <Image src={CerealOF} style={{ width: "6%", marginLeft: "10%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2} speed={0.5}>
                        <Image src={CerealOF} style={{ width: "5%", marginLeft: "65%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.3} speed={-0.3}>
                        <Image src={CerealOF} style={{ width: "5%", marginLeft: "30%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.1} speed={0}>
                        <div className='col-md-6 offset-md-3 text-center'>
                            <h1 className='font-punk-stamp text-secondary p-0 m-0' style={{ fontSize: isMobile ? "6rem": "10rem" }}>PART OF YOUR</h1>
                            <h1 className='font-punk-stamp text-primary p-0 mb-0 ms-0 me-0' style={{ fontSize: isMobile ? "7rem" : "13.5rem", marginTop: isMobile ? "-2.5rem": "-6rem" }} >BALANCED</h1>
                            <h2 className='font-punk-stamp text-secondary p-0 mb-0 me-0 ms-0' style={{ fontSize: isMobile ? "3.5rem": "6.4rem", marginTop: isMobile ? "-2rem": "-5rem" }} >BRANDED BREAKFAST!</h2>
                        </div>
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.2} speed={0.4}>
                        <Image src={Cereal} style={{ width: "6%", marginLeft: "67%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={3} speed={0}>
                        <ContactUs />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.4} speed={0.5}>
                        <Image src={Cereal} style={{ width: "4%", marginLeft: "78%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.45} speed={-0.4}>
                        <Image src={Cereal} style={{ width: "5%", marginLeft: "40%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.4} speed={-0.2}>
                        <Image src={Bowl} style={{ width: "50%", marginLeft: "45%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.75} speed={0.3}>
                        <Image src={Cereal} style={{ width: "5%", marginLeft: "78%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.85} speed={0.4}>
                        <Image src={Cereal} style={{ width: "4%", marginLeft: "67%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.99} speed={0.2}>
                        <Image src={Cereal} style={{ width: "7%", marginLeft: "70%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={2.98} speed={0.3}>
                        <Image src={Cereal} style={{ width: "5%", marginLeft: "10%" }} />
                    </ParallaxLayer>

                    <ParallaxLayer offset={3.9} speed={0}>
                        <Footer />
                    </ParallaxLayer>

                </Parallax>
            </div>
            {/* <ContactUs /> */}
            {/* <div className='w-100' style={{ position: "fixed", bottom: "0"}}>
                <ContactUs />
                <Footer />
            </div> */}
        </div>
    )
};

export default Services;