import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import ContactGif from '../../images/assets/WS_Test_8.gif';
import { useNavigate } from 'react-router-dom';


const ContactUs = () => {
    const navigate = useNavigate();

    const navigateToContact = () => {
        navigate('/contact');
    }
    return (
        <div className='w-100 bg-secondary'>
            <Container>
                <Row className="pt-5 pb-0 mb-0 bg-secondary">
                    <Col className="col-md-3 col-12 position-relative">
                        <div className="contact-us-headline-container w-100">
                            <h1 className="text-dark contact-us-headline" >READY TO</h1>
                            <h1 className="text-dark contact-us-headline" style={{ marginTop: "-3rem" }}>STAND OUT?</h1>
                        </div>
                    </Col>
                    <Col className="col-md-6 col-12">
                        <Image className="mw-100" src={ContactGif} style={{ cursor: "pointer" }} onClick={navigateToContact} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default ContactUs;