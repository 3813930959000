import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import PBLogo from '../../images/assets/PB_Logo.svg';
import IGLogo from '../../images/assets/instagram_icon.svg';


const Footer = () => {

    return (
        <>
            <footer>
                <Row className='mw-100 bg-primary ps-5 pe-5 pt-3 pb-3 ms-0 me-0'>
                    <Col className="col-md-3 text-start">
                        <Image src={PBLogo} style={{ height: "3rem"  }} />
                    </Col>
                    <Col className='col-md-6 text-center'>
                        <p className='text-secondary font-body mt-3'>COPYRIGHT 2024. PUNKBRANDS LLC. ALL RIGHTS RESERVED.</p>
                    </Col>
                    <Col className='col-md-3 text-end'>
                        <Image src={IGLogo} className='filter-primary mt-2' style={{ height: "2rem" }} />
                    </Col>
                </Row>
            </footer>
        </>
    )
};

export default Footer;