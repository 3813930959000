import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ContactUs from '../HomePage/ContactUsSection';


const Work = () => {

    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='mt-3 ps-5 pe-5 pt-5 pb-0 mb-0'>
                        <h1 className='font-secondary text-primary' style={{ fontSize: "10rem" }} >WORK</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 className='font-primary text-secondary ps-5 pe-5 mb-5' style={{ fontSize: "5rem" }}>Coming Soon...</h1>
                    </Col>
                </Row>
            </Container>
            <ContactUs />
        </div>
    )
};

export default Work;